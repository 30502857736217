<template>
	<PrimeConfirmPopup group="avatar" class="mt-0">
		<template #message="slotProps">
			<PrimeCard class="avatar-card">
				<template #header>
					<div class="flex flex-column justify-content-center align-items-center avatar-header p-3">
						<img v-if="profile?.logoUrl" v-modifyImg="profile?.logoUrl" :src="profile?.logoUrl" alt=""
							class="avatar-img" />
						<template v-else>
							<img alt="" src="../../assets/images/no-image.jpg" class="avatar-img" />
						</template>
						<p class="pt-3">{{ slotProps.message.message }}</p>
					</div>
				</template>
			</PrimeCard>
		</template>
	</PrimeConfirmPopup>
	<a class="flex p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors p-ripple"
		@click="showTemplate($event)">
		<img v-if="profile?.logoUrl" v-modifyImg="profile?.logoUrl" :src="profile?.logoUrl" alt=""
			class="mr-3 lg:mr-2 avatar-img-small" />
		<template v-else>
			<img alt="" src="../../assets/images/no-image.jpg" class="mr-3 lg:mr-2 avatar-img-small" />
		</template>
		<div class="block">
			<span class="text-600 font-medium text-sm">{{ profile?.name || '---' }}</span>
		</div>
		<span class="p-ink"></span>
	</a>
</template>
<script>
import { useRouter } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';
import { signout } from '@/services/auth.service';
import { useAuth } from '@/composables/useAuth';
import { useProfile } from '@/composables/userProfile';
import { REFRESH_TOKEN_KEY, RESPONSE_CODE } from '@/common/const/modules';
import Storage from '@/common/helpers/storage/localStorage';

export default {
	setup() {
		const confirm = useConfirm();
		const router = useRouter();
		const { profile } = useProfile();
		const { logout, error } = useAuth();

		const signOut = async () => {
			const { status, statusText } = await signout(Storage.get(REFRESH_TOKEN_KEY));
			if ([RESPONSE_CODE.SUCCESS, RESPONSE_CODE.NO_CONTENT].includes(status)) {
				logout();
				router.push('/login');
			} else {
				// can not revoke token
				error.value = statusText;
				logout();
				router.push('/login');
			}
		};

		const showTemplate = (event) => {
			confirm.require({
				target: event.currentTarget,
				group: 'avatar',
				message: `${profile.value?.name || '---'}`,
				acceptClass: 'p-2 pl-3 pr-3 mt-3 mb-2',
				rejectClass: 'profile p-button-outlined p-button-helps p-2 pl-3 pr-3 mt-3 mb-2',
				acceptLabel: 'Sign out',
				rejectLabel: 'Profile',
				accept: signOut,
				reject: () => {
					router.push('/profile');
				}
			});
		};

		return { profile, showTemplate, signOut };
	},
	directives: {
		modifyImg: (el, binding) => {
			if (binding.value instanceof File) {
				const reader = new FileReader();
				reader.readAsDataURL(binding.value);
				reader.onloadend = function () {
					el.src = reader.result;
				};
			} else {
				el.src = binding.value.replace('data:image/png,base64', 'data:image/png;base64');
			}
		}
	}
};
</script>
<style lang="scss">
@import '@/assets/scss/theme-variable.scss';

.avatar {
	&-card {
		width: 20em;
		box-shadow: none !important;

		.p-card-content {
			padding: 0;
		}
	}

	&-header {
		background-color: $p-primary-color ;
		color: white;

	}

	&-img {
		width: 100px !important;
		height: 100px;
		border-radius: 50px;

		&-small {
			width: 32px;
			height: 32px;
			border-radius: 50px;
		}
	}
}

.profile {
	float: left;
}
</style>
