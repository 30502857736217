export const AD_SETTING_MANAGEMENT = 'Ads Attribute Settings';
export const LOCATION_MANAGEMENT = 'Ads Attribute Setting > Location Customization';
export const NEARBY_MANAGEMENT = 'Ads Attribute Setting > Nearby Customization';
export const TIME_MANAGEMENT = 'Ads Attribute Setting > Time Customization';

export const ENUM_AD_SETTING = {
	LOCATION: 'location',
	TIME: 'time',
	NEARBY: 'nearby'
};
