import { ref } from 'vue';
const blockedDocument = ref(false);

export const useBlockUI = () => {
	const blockDocument = () => {
		blockedDocument.value = true;
	};
	const unblockDocument = () => {
		blockedDocument.value = false;
	};
	return {
		blockDocument,
		unblockDocument,
		blockedDocument
	};
};
