import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { globalMixins } from './mixins';
import init from './init';
import PrimeVue from 'primevue/config';
import globalComponents from './components/index';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import './assets/scss/styles.scss';

const appInstance = createApp(App);

appInstance.mixin(globalMixins);
appInstance.use(init);
appInstance.use(PrimeVue, { inputStyle: 'filled', ripple: true });
appInstance.use(ToastService);
appInstance.use(ConfirmationService);
appInstance.use(globalComponents);
appInstance.use(router);
appInstance.mount('#app');
