import constants from './common/const';
import Notify from './common/helpers/notify';
import ErrorHandler from './common/helpers/api/errorHandler';

const plugin = {
	install(app) {
		app.config.globalProperties.$const = constants;
		app.config.globalProperties.$notify = Notify;
		app.config.globalProperties.$errorHandler = ErrorHandler;
	}
};

export default plugin;
