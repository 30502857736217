<template>
	<ul class="list-none p-3 m-0">
		<li v-for="(item, index) in menu" :key="index">
			<div v-if="item?.isHeader"
				class="p-2 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple">
				<span class="font-medium">{{ item?.name }}</span>
				<span class="p-ink"></span>
			</div>
			<ul v-if="!item?.isHeader" class="list-none p-0 m-0 overflow-hidden">
				<li>
					<router-link
						class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
						:to="item?.router" v-if="item?.router && item?.router?.path">
						<em :class="item?.icon"></em>
						<span class="font-medium">{{ item?.name }}</span>
						<span class="p-ink"></span>
					</router-link>
					<template v-if="item?.items?.length > 0">
						<ul class="list-none py-0 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out"
							v-for="(subItem, subItemIndex) in item?.items" :key="subItemIndex">
							<li>
								<router-link
									class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
									:to="subItem?.router" v-if="subItem?.router && subItem?.router?.path">
									<em :class="subItem?.icon"></em>
									<span class="font-medium">{{ subItem?.name }}</span>
									<span class="p-ink"></span>
								</router-link>
							</li>
						</ul>
					</template>
				</li>
			</ul>
		</li>
	</ul>
</template>
<script>
import { computed } from 'vue';

export default {
	props: {
		menuItems: Array
	},
	setup(props) {
		const menu = computed(() => props.menuItems);

		return {
			menu
		};
	}
};
</script>
