import { axios as api } from '../common/helpers/api';
import { API_URL, API_VERSION, SERVICES } from '../common/configs/api';

export function getProfile() {
	return api.get(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.PROFILE}`);
}

export function updateProfile(formData = {}) {
	return api.put(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.PROFILE}`, formData, { useFormData: true });
}
export function updateAvatar(avatar = {}) {
	return api.put(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.PROFILE}/${API_URL.AVATAR}`, avatar, { useFormData: true });
};
export function getAvatar() {
	return api.get(API_URL.AVATAR);
}
