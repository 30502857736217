import axios from './init';
import { ObjectToFormData } from '@/common/helpers/formDataCompiler';
import { handleApiError } from './interceptors/error.interceptor';
import { REQUEST_TYPE } from '@/common/configs/api';

/**
 * Requests to API
 * @param {String} type
 * @param {String} url
 * @param {Object} queryParam
 * @param {Object} data
 * @param {import('axios').AxiosRequestConfig} options
 * If you wish to send FormData instead of JSON, set options.useFormData to true.
 */
const request = (url, options = { method: REQUEST_TYPE.GET, useFormData: false }) => {
	const data = { ...(options.data || {}) };
	const reqOptions = { ...options, data };

	if ([REQUEST_TYPE.POST, REQUEST_TYPE.PUT].includes(options.method) && options.useFormData) {
		reqOptions.data = ObjectToFormData(data);
	}

	const req = axios(url, reqOptions);
	return req.catch(handleApiError);
};

/**
 * Send GET Request to API
 * @param {String} url
 * @param {AxiosRequestConfig} options
 */
const get = (url, options = {}) => (
	request(url, { ...options, method: REQUEST_TYPE.GET })
);

/**
 * Send POST Request to API
 * @param {String} url
 * @param {Object} data
 * @param {AxiosRequestConfig} options
 * If you wish to send FormData instead of JSON, set options.useFormData to true.
 */
const post = (url, data = {}, options = {}) => (
	request(url, { ...options, data, method: REQUEST_TYPE.POST })
);

/**
 * Send PUT Request to API
 * @param {String} url
 * @param {Object} data
 * @param {AxiosRequestConfig} options
 * If you wish to send FormData instead of JSON, set options.useFormData to true.
 */
const put = (url, data = {}, options = {}) => (
	request(url, { ...options, data, method: REQUEST_TYPE.PUT })
);

/**
 * Send DELETE Request to API
 * @param {String} url
 * @param {Object} queryParam
 * @param {AxiosRequestConfig} options
 */
const remove = (url, options = {}) => (
	request(url, { ...options, method: REQUEST_TYPE.DEL })
);

export default {
	get,
	post,
	put,
	delete: remove
};
