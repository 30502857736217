import { authHeader } from '@/common/helpers/authHeader';

export const JwtIntercept = (request) => {
	request.use((config) => {
		return {
			...config,
			headers: authHeader()
		};
	});
};
