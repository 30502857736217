// App constants
export const APP_NAME = 'LightX';
export const SET_PAGE_TITLE = 'setPageTitle';
export const SET_BREADCRUMB = 'setBreadcrumb';
export const SET_TITLE = 'setTitle';

// Containers constant
export const DASHBOARD = 'Dashboard';
export const TERMS_CONDITIONS = 'Terms & Conditions';
export const PROFILE = 'My Profile';
export const CLIENTS_EDITION = 'Edit client';
export const CLIENTS_MANAGEMENT = 'Clients management';
export const CLIENTS_CREATION = 'Add client';

export const AUTH_KEY = 'token';
export const TOKEN_TYPE = 'tokenType';
export const USER_KEY = 'user';
export const REFRESH_TOKEN_KEY = 'refreshToken';

export const MAX_LENGTH_INPUT_TEXT = 60;
export const MAX_LENGTH_INPUT_PASSWORD = 12;
export const MAX_LENGTH_INPUT_PHONE_NUMBER = 12;
export const MAX_LENGTH_TEXT_AREA = 255;
export const RESPONSE_CODE = {
	SUCCESS: 200,
	NO_CONTENT: 204,
	UNAUTHORIZED: 401,
	NOT_PERMISSION: 403,
	NOT_FOUND: 404,
	UNPROCESSABLE_ENTITY: 422,
	BAD_REQUEST: 400,
	INTERNAL_SERVER_ERROR: 500
};

export const ROLES = {
	ADMIN: 'Super Administrator'
};
