export const globalMixins = {
	methods: {

		uid(id) {
			return `${this._uid}_${id}`; // eslint-disable-line no-underscore-dangle
		},

		noop() {
			// noop for stopping propagation
		}
	}
};
