import { axios as api } from '../common/helpers/api';
import { API_URL, API_VERSION, SERVICES } from '../common/configs/api';

export function signup(user = {}) {
	return api.post(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.REGISTER}`, user);
}

export function signin(login = {}) {
	return api.post(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.SIGN_IN}`, login);
}

export function signout(refreshTokens) {
	return api.post(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.SIGN_OUT}/${refreshTokens}`);
}

export function refreshToken(refreshTokens) {
	return api.get(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.SIGN_IN}/${refreshTokens}`);
}
