export const BASE_API_URL = process.env.VUE_APP_BASE_API;
export const LINK_DIRECT_AGENCY = process.env.VUE_APP_LINK_APP_AGENCY;
export const API_VERSION = '/api/v1/';

export const SERVICES = {
	IDENTITY: 'gateway/identity',
	CORE: 'gateway/core',
	PAYMENT: 'gateway/payment'
};

export const API_URL = {
	// Guest constant
	LOGIN: 'signin',
	REGISTER: 'signup',
	SIGN_OUT: 'Auth/logout',
	SIGN_IN: 'Auth/token',

	// clients constant
	CLIENT: 'Clients',

	AD_SETTING: 'attributes/default',
	AD_ATTRIBUTE: 'attributes',
	AGENCIES: 'Tenants',
	USER_TENANTS: 'Users',

	// Current user constant
	PROFILE: 'Users/profile',
	AVATAR: 'avatar',

	// device
	DEVICE: 'clients',

	// common Geos
	GEOS_COUNTRY: 'Geos/countries',
	GEOS_CITY: 'Geos/cities',
	GEOS_STATE: 'Geos/states',

	// common constant
	DISTRICT: 'districts',
	TIME_ZONES: 'timezones',
	COUNTRY: 'countries',
	STATE: 'states',
	CITY: 'cities',

	// Sparks
	SPARK: 'sparks-setting',
	SET_COST_PAYMENT: 'settings',

	// Role
	ROLES: 'Users/roles'

};

export const REQUEST_ACTION = {
	ADD: 'add',
	EDIT: 'edit',
	UPDATE: 'update',
	DELETE: 'delete'
};

export const REQUEST_TYPE = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DEL: 'DELETE',
	PATCH: 'PATCH'
};
