import { createRouter, createWebHistory } from 'vue-router';
import { AUTH_KEY } from '@/common/const/modules/index';
import Storage from '@/common/helpers/storage/localStorage';
import MainLayout from '../layout/MainLayout.vue';

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: () => import('../pages/Login.vue')
	},
	{
		path: '/',
		name: '',
		component: MainLayout,
		children: [
			{
				path: '/',
				component: () => import('../pages/Home.vue')
			},
			{
				path: 'profile',
				name: 'Profile',
				component: () => import('../pages/Profile/Profile.vue')
			},
			{
				path: 'agencies',
				name: 'Agencies Management',
				component: () => import('../pages/Agencies/AgenciesList.vue')
			},
			{
				path: 'devices',
				name: 'Devices Management',
				component: () => import('../pages/Devices/ListDevice.vue')
			},
			{
				path: 'devices/register-devices',
				name: 'Add Device',
				component: () => import('../pages/Devices/RegisterDevice/RegisterDevice.vue')
			},
			{
				path: 'devices/edit-devices/:id',
				name: 'Edit Device',
				component: () => import('../pages/Devices/EditDevice/EditDevice.vue')
			},
			{
				path: 'agency/:tenantId/:userId',
				name: 'Agency Detail',
				component: () => import('../pages/Agencies/AgencyDetail.vue')
			},
			{
				path: 'advertise',
				name: 'Advertise Management',
				component: () => import('../pages/Sparks/SparkManagement.vue')
			},
			{
				path: 'advertise/add-spark',
				name: 'Create Sparks',
				component: () => import('../pages/Sparks/AddSparks/AddSpark.vue')
			},
			{
				path: 'advertise/edit-spark/:id',
				name: 'Edit Spark',
				component: () => import('../pages/Sparks/EditSpark/EditSpark.vue')
			},
			{
				path: '/payment',
				name: 'Payment Setting',
				component: () => import('../pages/Payment/PaymentSetting.vue')
			},
			{
				path: '/setting-permission',
				name: 'Setting Permission',
				component: () => import('../pages/Permission/SettingPermission.vue')
			},
			{
				path: '/setting-permission/edit-permission/:id/:name',
				name: 'Edit Permission',
				component: () => import('../pages/Permission/EditPermission/EditPermission.vue')
			}
		]
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior: (to, from, savedPosition) => (savedPosition || { x: 0, y: 0 })
});

router.beforeEach((to, from, next) => {
	if ((to.name !== 'Login' && !Storage.get(AUTH_KEY)) || !to.matched.length) {
		next('/login');
		return;
	}

	next();
});

export default router;
