import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import CheckBox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Ripple from 'primevue/ripple';
import Tooltip from 'primevue/tooltip';
import Menu from 'primevue/menu';
import Card from 'primevue/card';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import Breadcrumb from 'primevue/breadcrumb';
import FileUpload from 'primevue/fileupload';
import ScrollPanel from 'primevue/scrollpanel';
import BlockUI from 'primevue/blockui';
import Loading from 'vue-loading-overlay';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup'; // optional for column grouping
import Row from 'primevue/row'; // optional for row
import TabView from 'primevue/tabview';
import Textarea from 'primevue/textarea';
import TabPanel from 'primevue/tabpanel';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import Sidebar from 'primevue/sidebar';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.min.css';
import 'vue-loading-overlay/dist/vue-loading.css';

const globalComponents = {
	install(app) {
		app.component('PrimeInputNumber', InputNumber);
		app.component('PrimeButton', Button);
		app.component('PrimeToast', Toast);
		app.component('PrimeCheckBox', CheckBox);
		app.component('PrimeInputText', InputText);
		app.component('PrimeMenu', Menu);
		app.component('PrimeCard', Card);
		app.component('PrimeConfirmPopup', ConfirmPopup);
		app.component('PrimeConfirmDialog', ConfirmDialog);
		app.component('PrimeBreadcrumb', Breadcrumb);
		app.component('PrimeFileUpload', FileUpload);
		app.component('PrimeScrollPanel', ScrollPanel);
		app.component('PrimeBlockUI', BlockUI);
		app.component('LoadingOverlay', Loading);
		app.component('PrimeDataTable', DataTable);
		app.component('PrimeColumn', Column);
		app.component('PrimeColumnGroup', ColumnGroup);
		app.component('PrimeRow', Row);
		app.component('PrimeTabView', TabView);
		app.component('PrimeTabPanel', TabPanel);
		app.component('PrimeDropdown', Dropdown);
		app.component('PrimeTextarea', Textarea);
		app.component('PrimeInputSwitch', InputSwitch);
		app.component('PrimeSidebar', Sidebar);
		app.component('PrimeDialog', Dialog);

		app.directive('ripple', Ripple);
		app.directive('tooltip', Tooltip);
	}
};

export default globalComponents;
