// formData - instance of FormData object
// data - object to post
function _getFormData (val, formData = new FormData(), namespace = '') {
	if ((typeof val !== 'undefined') && (val !== null)) {
		if (val instanceof Date) {
			formData.append(namespace, val.toISOString());
		} else if (val instanceof Array) {
			for (const element of val) {
				_getFormData(element, formData, namespace + '[]');
			}
		} else if (typeof val === 'object' && !(val instanceof File)) {
			for (const propertyName in val) {
				if (Object.prototype.hasOwnProperty.call(val, propertyName)) {
					_getFormData(val[propertyName], formData, namespace ? namespace + '.' + propertyName + '' : propertyName);
				}
			}
		} else if (typeof val === 'object' && val instanceof File) {
			formData.append(namespace, val);
		} else {
			formData.append(namespace, val.toString());
		}
	}

	return formData;
}

/**
 * Parse object into FormData
 * @param {Object} params
 */
export const ObjectToFormData = (params = {}) => {
	return _getFormData(params);
};
