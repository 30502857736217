import { reactive, toRefs } from 'vue';
import Storage from '@/common/helpers/storage/localStorage';
import { AUTH_KEY, REFRESH_TOKEN_KEY, TOKEN_TYPE } from '@/common/const/modules/index';

const authState = reactive({
	user: undefined,
	refreshToken: undefined,
	token: undefined,
	error: undefined,
	rememberMe: false
});

export const useAuth = () => {
	const setUser = (payload, isRememeber = false) => {
		if (payload) {
			if (isRememeber) {
				const exdate = +new Date() + payload?.expires_in;
				window.document.cookie = `authToken=${payload?.access_token};path=/;expires=${new Date(exdate)?.toISOString()}`;
				window.document.cookie = `refreshToken=${payload?.refresh_token};path=/;expires=${new Date(exdate)?.toISOString()}`;
			} else {
				window.document.cookie = 'authToken=;path=/;expires=-1';
				window.document.cookie = 'refreshToken=;path=/;expires=-1';
			}

			Storage.set(AUTH_KEY, payload?.access_token);
			Storage.set(TOKEN_TYPE, payload?.token_type);
			Storage.set(REFRESH_TOKEN_KEY, payload?.refresh_token);

			authState.token = payload?.access_token;
			authState.refreshToken = payload?.refresh_token;
			authState.user = payload;
			authState.rememberMe = isRememeber;
		}
	};

	const logout = () => {
		Storage.clear();
		window.document.cookie = 'authToken=;path=/;expires=-1';
		window.document.cookie = 'refreshToken=;path=/;expires=-1';

		authState.user = undefined;
		authState.token = undefined;
		authState.refreshToken = undefined;
		authState.rememberMe = false;
	};

	return {
		setUser,
		logout,
		...toRefs(authState)
	};
};
