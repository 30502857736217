<template>
  <LoadingOverlay v-model:active="blockedDocument" color="var(--blue-500)" :is-full-page="true"/>
  <PrimeToast />
  <router-view/>
</template>
<script>
import { useBlockUI } from '@/composables/useBlockUI';
export default {
	setup() {
		const { blockedDocument } = useBlockUI();
		return {
			blockedDocument
		};
	}
};
</script>
