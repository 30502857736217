import axios from 'axios';
import { BASE_API_URL } from '@/common/configs/api';
import { JwtIntercept } from './interceptors/jwt.interceptor';
import { ErrorIntercept } from './interceptors/error.interceptor';

const axiosInstance = axios.create({
	baseURL: BASE_API_URL,
	headers: {},
	withCredentials: true
});

const registerInterceptors = (axiosInst) => {
	JwtIntercept(axiosInst.interceptors.request);
	ErrorIntercept(axiosInst.interceptors.response);
};

registerInterceptors(axiosInstance);

export default axiosInstance;
