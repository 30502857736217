// register the plugin on vue
import Toasted from 'vue-toasted';
import '@/assets/scss/notify.scss';

const toasted = {
	install(Vue) {
		Vue.use(Toasted, { theme: 'toasted-primary', iconPack: 'fontawesome', duration: 155000 });

		class Notify {
			getText (text) {
				return `<div class="toasted-content">${text}</div>`;
			}

			show (text = '', options = {}) {
				options = Object.assign(options, {
					action: {
						// text: 'close',
						icon: 'datetime.js.js',
						onClick: (e, toastObject) => {
							toastObject.goAway(0);
						}
					}
				});

				Vue.toasted.show(this.getText(text), options).goAway(3500);
			}

			success (text = '', options = {}) {
				options = Object.assign(options, {
					action: {
						icon: 'datetime.js.js',
						onClick: (e, toastObject) => {
							toastObject.goAway(0);
						}
					}
				});

				Vue.toasted.success(this.getText(text), options).goAway(5000);
			}

			info (text = '', options = {}) {
				options = Object.assign(options, {
					action: {
						icon: 'datetime.js.js',
						onClick: (e, toastObject) => {
							toastObject.goAway(0);
						}
					}
				});
				Vue.toasted.info(this.getText(text), options).goAway(3500);
			}

			error (text = '', options = {}) {
				options = Object.assign(options, {
					icon: 'exclamation-triangle',
					action: {
						icon: 'datetime.js.js',
						onClick: (e, toastObject) => {
							toastObject.goAway(0);
						}
					}
				});
				Vue.toasted.error(this.getText(text), options).goAway(5000);
			}

			clear () {
				Vue.toasted.clear();
			}
		}

		return new Notify(Toasted);
	}
};

export default toasted;
