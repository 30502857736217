<template>
	<div class="block-content">
		<div class="menu-bar">
			<div class="min-h-screen flex relative lg:static surface-ground">
				<div v-if="isDisableMenu" id="app-sidebar"
					class=" surface-section h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none">
					<div class="flex flex-column  surface-900 h-full">
						<div class="flex align-items-center px-5 flex-shrink-0 logo-wrapper">
							<img src="../assets/lightX_logo.png" class="mr-5" alt="Image" width="125" height="28" />
							<div class="text-100 ml-8">
								<em class="pi pi-align-right text-lg cursor-pointer" @click="hideNavigate"></em>
							</div>
						</div>
						<!-- Sidebar -->
						<SideBar :menuItems="state.menuList" />
					</div>
				</div>
				<div class="min-h-screen flex flex-column relative flex-auto">
					<div
						class="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border relative lg:static top-bar ">
						<em v-if="!isDisableMenu" class="pi pi-align-justify text-lg cursor-pointer" @click="hideNavigate"></em>
						<div class="flex">
						</div>
						<a class="cursor-pointer block lg:hidden text-700 p-ripple">
							<em class="pi pi-ellipsis-v text-2xl"></em>
							<span class="p-ink"></span>
						</a>
						<ul
							class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
							<li class="border-top-1 surface-border lg:border-top-none">
								<UserToolBar></UserToolBar>
							</li>
						</ul>
					</div>
					<div class="p-5 flex flex-column flex-auto overflow-y-auto">
						<div class="border-2 border-dashed surface-border border-round surface-section flex-auto sm:p-3">
							<PrimeScrollPanel class="custombar">
								<router-view></router-view>
							</PrimeScrollPanel>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!---->
	</div>
</template>

<script>
import { ref, computed, reactive, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useProfile } from '@/composables/userProfile';
import { getProfile } from '@/services/user.service';
import { useToast } from 'primevue/usetoast';
import { useBlockUI } from '@/composables/useBlockUI';
import { RESPONSE_CODE } from '@/common/const/modules';
import SideBar from './SideBar.vue';
import UserToolBar from '@/components/UserToolBar/UserToolBar.vue';
import menuItems from './menuItems';

export default {
	name: 'MainLayout',
	components: {
		SideBar,
		UserToolBar
	},
	setup() {
		const { setProfile, profile } = useProfile();
		const { unblockDocument, blockDocument } = useBlockUI();
		const toast = useToast();
		const route = useRoute();
		const name = computed(() => route?.name);
		const menuList = ref(menuItems || []);
		const isDisableMenu = ref(true);
		const state = reactive({
			home: {
				icon: 'pi pi-home',
				to: '/'
			},
			items: [{ label: name }],
			menuList
		});
		const hideNavigate = () => {
			return (isDisableMenu.value = !isDisableMenu.value);
		};
		onMounted(async () => {
			// get profile data;
			blockDocument();
			const { data, status, statusText } = await getProfile();
			if (data && status === RESPONSE_CODE.SUCCESS) {
				setProfile(data);
				unblockDocument();
			} else {
				unblockDocument();
				toast.add({ severity: 'error', summary: 'Error', detail: statusText, life: 3000 });
			}
		});

		return {
			isDisableMenu,
			hideNavigate,
			state,
			profile,
			setProfile
		};
	}

};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/theme-variable.scss';

#app-sidebar {
	width: 280px;
}

.logo-wrapper {
	height: 60px;
}

.menu-bar {
	height: 100vh;
	overflow: hidden
}

.top-bar {
	height: 60px;
}

:deep(.p-scrollpanel) {
	p {
		padding: .5rem;
		line-height: 1.5;
		margin: 0;
	}

	&.customSidebar {
		height: 100vh
	}

	&.custombar {
		height: calc(100vh - 200px);

		.p-scrollpanel-bar {
			background-color: $p-primary-color;
			opacity: 1;
			transition: background-color .2s;

		}
	}
}
</style>
