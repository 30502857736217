import { AES, enc } from 'crypto-js';

const get = (key = '') => {
	const value = localStorage.getItem(key);
	if (value) {
		return AES.decrypt(value, key).toString(enc.Utf8);
	};
	return '';
};

const set = (key = '', value = '') => {
	if (typeof value === 'object' && value) {
		return localStorage.setItem(key, JSON.stringify(value));
	} else {
		return localStorage.setItem(key, AES.encrypt(value, key).toString());
	}
};

const remove = (key = '') => {
	return localStorage.removeItem(key);
};

const clear = () => {
	return localStorage.clear();
};

export default {
	get,
	set,
	remove,
	clear
};
