export default [
	{
		isHeader: true,
		name: 'MAIN NAVIGATION',
		icon: 'pi pi-chevron-down ml-auto'
	},
	{
		icon: 'pi pi-home mr-2',
		name: 'Dashboard',
		router: {
			path: '/'
		}
	},
	{
		icon: 'pi pi-user mr-2',
		name: 'Agencies',
		router: {
			path: '/agencies'
		}
	},
	{
		icon: 'pi pi-mobile mr-2',
		name: 'Devices',
		router: {
			path: '/devices'
		}
	},
	{
		icon: 'pi pi-database mr-2',
		name: 'Advertise',
		router: {
			path: '/advertise'
		}
	},
	{
		icon: 'pi pi-credit-card mr-2',
		name: 'Payment Setting',
		router: {
			path: '/payment'
		}
	},
	{
		icon: 'pi pi-shield mr-2',
		name: 'Setting Permission',
		router: {
			path: '/setting-permission'
		}
	}
];
