import { reactive, toRefs } from 'vue';

const profileState = reactive({
	profile: null,
	error: undefined
});

export const useProfile = () => {
	const setProfile = (payload) => {
		if (payload) {
			profileState.profile = payload;
		}
	};

	return {
		setProfile,
		...toRefs(profileState)
	};
};
