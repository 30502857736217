import Notify from '../notify';

export class ErrorsHandler {
	showServerErrors (message, component) {
		if (typeof message === 'string' || message.single) {
			Notify.error(message.single || message);
			return;
		}
		if (component) {
			this.showClientErrors(component, message);
		}
		(Array.isArray(message) ? message : []).forEach(error => {
			const keys = Object.keys(error);
			Notify.error(error[keys[0]]);
		});
	}

	showClientErrors (component, errors) {
		if (!this.errors) {
			return;
		}
		errors = errors || {};
		Object.keys(errors).forEach(key => {
			component.errors.add({
				field: key,
				msg: errors[key][0]
			});
		});
	}

	// Recursively finds the closest parent that has the specified class
	closestParent (child, className) {
		if (!child || child === document) {
			return null;
		}
		if (child.classList.contains(className)) {
			return child;
		} else {
			return this.closestParent(child.parentNode, className);
		}
	}

	// This function inserts newNode after referenceNode
	insertAfter (referenceNode, newNode) {
		referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
	}
}

export default new ErrorsHandler();
